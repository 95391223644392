import React, { useState, useRef, useCallback } from 'react';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import formStyle from '../components/form.module.css';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

const Form = () => {
  const emailRef = useRef(null);
  const messageRef = useRef(null);
  const [state, setState] = useState('NONE');
  const { executeRecaptcha } = useGoogleReCaptcha();

  const submit = useCallback((e) => {
    e.preventDefault();

    setState('LOADING');

    executeRecaptcha('contact/post')
      .then((recaptchaToken) => {
        fetch(`${process.env.GATSBY_BACKEND_ENDPOINT}/api/contact`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            email: emailRef.current.value,
            text: messageRef.current.value,
            recaptchaToken,
          }),
        })
          .then((res) => res.json())
          .then((res) => {
            if (res.success) {
              emailRef.current.value = '';
              messageRef.current.value = '';

              return setState('SUCCESS');
            }

            setState('ERROR');
          })
          .catch((err) => {
            setState('ERROR');
          });
      })
      .catch((err) => {
        setState('ERROR');
      });
  }, []);

  return (
    <div>
      <div>
        <form onSubmit={submit}>
          <label>Email :</label>
          <input required ref={emailRef} disabled={state === 'LOADING'} type="email" name="email" />
          <label>Message :</label>
          <input
            required
            ref={messageRef}
            disabled={state === 'LOADING'}
            type="text"
            name="message"
          />
          {state === 'SUCCESS' ? (
            <p>Merci !</p>
          ) : (
            <button
              className={formStyle.mds_cta_submit}
              type="submit"
              disabled={state === 'LOADING'}
            >
              Envoyer
            </button>
          )}
          {state === 'ERROR' && <p>Ooops ! Il y a eu une erreur, veuillez recommencer.</p>}
        </form>
      </div>
    </div>
  );
};

export default function (props) {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={'6LfXej4aAAAAADVXJU56-pGGTwj55DC8hPJaxijM'}
      language={'fr'}
    >
      <Form {...props} />
    </GoogleReCaptchaProvider>
  );
}
